import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import './privacy-policy.scss'
const PrivacyPolicy = () => (
<Layout>
<main className="privacy-main main">
    <SEO title="Privacy Policy" />
    
    <div className="container">
    <h1 className="main-title">Privacy Policy</h1>
    <p>Questo sito ( www.eugeniopilastroluca.it ) potrebbe raccogliere dei dati personali.</p>
    </div>
    
    <h2 className="main-title">Titolare del trattamento</h2>
    <p>Per richiedere ulteriori informazioni e per far valere i propri diritti in materia di privacy e protezione dei dati personali, è possibile contattare il titolare del trattamento, Eugenio Pilastro Luca, all'indirizzo e-mail: info@eugeniopilastroluca.it</p>
    
    <h2 className="main-title">Dati raccolti e finalità del trattamento</h2>
    <p>Quando visitate questo sito, l'hosting provider raccoglie gli indirizzi IP ed i dati di navigazione per finalità statistiche e tecniche necessarie alla corretta erogazione del servizio.</p>
    
    <p>Questo sito utilizza caratteri Google Fonts, questo significa che il servizio Google potrebbe raccogliere gli indirizzi IP ed i dati di utilizzo come dettagliato nell'informativa privacy del servizio.</p>
    
    <p>Questo sito utilizza il servizio Google Analytics configurato con anonimizzazione ip e non autorizzato a condividire i dati di utilizzo raccolti con altri servizi Google. I dati raccolti attraverso Google Analytics sono utilizzati a fini statistici e per la verifica del corretto funzionamento del sito. </p>
    <p>Google Analytics utilizza dei Cookie ( _ga, _gat, _gid ) per raccogliere questi dati di navigazione, come dettagliato dalla privacy policy del servizio</p>
    
    

   <div className="button-container" style={{
            height: `auto`,
            
          }}>
    <div className="button">
    <Link to="/">Home</Link>
    </div>
    </div>
  </main>
  </Layout>
  
)

export default PrivacyPolicy
